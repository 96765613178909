
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { Gutter } from "ant-design-vue/lib/grid/Row";
import useBreakpoint from "ant-design-vue/lib/_util/hooks/useBreakpoint";
import { Breakpoint } from "ant-design-vue/lib/_util/responsiveObserve";

type ResponsiveColumnScreenMap = { [k in Breakpoint]?: number };

/** Utility */
const ASCENDING_BREAKPOINTS: Breakpoint[] = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "xxxl",
];

export default defineComponent({
  props: {
    columnCounts: {
      type: Object as PropType<ResponsiveColumnScreenMap>,
      required: true,
    },
    items: { type: Array as PropType<unknown[]>, required: true },
    loading: Boolean,
    loadMoreLoading: Boolean,
    hasNextPage: Boolean,
    colGutter: Object as PropType<Gutter>,
  },
  emits: ["on-load-more"],
  setup(props) {
    const { t } = useI18n();

    const currentBreakpoint = useBreakpoint();
    const currentColumnCount = computed<number>(() => {
      let columnCount = 1;

      for (const breakpoint of ASCENDING_BREAKPOINTS) {
        const isScreen = currentBreakpoint.value[breakpoint];
        const customCount = props.columnCounts[breakpoint];

        if (isScreen && customCount) {
          columnCount = customCount;
        }
      }

      return columnCount;
    });

    const columns = computed(() => {
      // Return 2d array with a single column of all items
      if (!currentColumnCount.value || currentColumnCount.value <= 1)
        return [props.items];

      const container = [] as Array<unknown[]>;

      for (let i = 0; i < currentColumnCount.value; i++) {
        container.push([] as unknown[]);
      }

      for (let i = 0; i < (props.items.length ?? 0); i++) {
        const colIndex = i % currentColumnCount.value;
        const item = props.items[i];

        container[colIndex].push(item);
      }

      return container;
    });

    return { t, columns, currentBreakpoint, currentColumnCount };
  },
});
