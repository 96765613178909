import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "creator-content-media" }
const _hoisted_2 = { class: "creator-content-media__heading" }
const _hoisted_3 = { class: "creator-content-media__filters" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "creator-content-media__empty"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "creator-content-media__list",
  id: "creator-content-media__list"
}
const _hoisted_8 = {
  key: 0,
  class: "creator-content-media__load-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CreatorContentMediaItem = _resolveComponent("CreatorContentMediaItem")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_StackedGrid = _resolveComponent("StackedGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (
            (!_ctx.isEmpty(_ctx.contentQueryString) || !_ctx.isEmpty(_ctx.creatorQueryString)) &&
            !_ctx.creatorContentSubmissionsEmpty &&
            !_ctx.creatorContentSubmissionsLoading
          )
          ? (_openBlock(), _createBlock(_component_a_typography_title, {
              key: 0,
              level: 2
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.searchResultTitle), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.t("Filter Creator")), 1),
        _createVNode(_component_FormGroup, {
          model: _ctx.internalState,
          onFinish: _ctx.handleSubmit,
          class: "creator-content-media__filters__form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              value: _ctx.internalState.creator,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalState.creator) = $event)),
              name: "creator",
              placeholder: _ctx.t('All')
            }, null, 8, ["value", "placeholder"]),
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SearchOutlined)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "onFinish"])
      ])
    ]),
    (
        _ctx.creatorContentSubmissionsLoading &&
        !_ctx.creatorContentSubmissionsFetchMoreLoading
      )
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: "",
          width: "100%"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.creatorContentSubmissionsEmpty)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_a_typography_title, {
                  class: "creator-content-media__empty__title",
                  level: 2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.noResultTitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_paragraph, { class: "creator-content-media__empty__paragraph" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t(
              "Are you interested to purchase User-Generated Contents for your brand? Reach out to us so we can gather the creators and contents for you!"
            )), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.handleContact
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Contact us")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_StackedGrid, {
                    "col-gutter": 16,
                    items: _ctx.creatorContentSubmissions,
                    "column-counts": { xxxl: 6, xxl: 5, xl: 4, lg: 3, sm: 2, xs: 1 }
                  }, {
                    columns: _withCtx(({ columns }) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns, (col, i) => {
                        return (_openBlock(), _createBlock(_component_a_col, {
                          key: i,
                          class: "gap-4"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(col, (item) => {
                              return (_openBlock(), _createBlock(_component_CreatorContentMediaItem, {
                                key: item?.id,
                                item: item,
                                selected: 
                    _ctx.creatorContentCartStore.ids.includes(String(item?.id ?? ''))
                  ,
                                "hide-add-to-cart": _ctx.hideAddToCart,
                                onOnPurchase: _ctx.addToCart,
                                onOnRemove: _ctx.removeFromCart
                              }, null, 8, ["item", "selected", "hide-add-to-cart", "onOnPurchase", "onOnRemove"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["items"])
                ]),
                (_ctx.creatorContentSubmissionsHasNextPage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_a_button, {
                        onClick: _ctx.creatorContentSubmissionsFetchMore,
                        loading: _ctx.creatorContentSubmissionsFetchMoreLoading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "loading"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ]))
  ]))
}