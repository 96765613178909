import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "creator-content-search__content" }
const _hoisted_3 = {
  id: "creator-content-search__footer",
  class: "creator-content-search__footer"
}
const _hoisted_4 = { class: "creator-content-search__footer-wrapper flex justify-end" }
const _hoisted_5 = { class: "creator-content-search__footer__content" }
const _hoisted_6 = { class: "flex items-center gap-2" }
const _hoisted_7 = { class: "creator-content-search__footer__label flex gap-1 flex-col items-end md:flex-row md:items-center" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatorContentCoverHeading = _resolveComponent("CreatorContentCoverHeading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_CreatorContentCoverForm = _resolveComponent("CreatorContentCoverForm")!
  const _component_CreatorContentMedia = _resolveComponent("CreatorContentMedia")!
  const _component_CreatorContentLayout = _resolveComponent("CreatorContentLayout")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_skeleton_button = _resolveComponent("a-skeleton-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_ShoppingCartOutlined = _resolveComponent("ShoppingCartOutlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreatorContentLayout, {
      "background-reversed": "",
      "show-cover": "",
      class: "creator-content-search"
    }, {
      coverContent: _withCtx(() => [
        _createVNode(_component_CreatorContentCoverHeading, { "max-title": "" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Search for your brand name.")), 1)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.t(
            "Type your brand here to see all the submitted content of creators for you."
          )), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_CreatorContentCoverForm, {
          model: _ctx.coverFormState,
          onFinish: _ctx.submitHandler,
          class: "creator-content-search__cover-form mt-12"
        }, {
          buttonText: _withCtx(() => [
            (_ctx.isXs)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.t("Search ")), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_SearchOutlined)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              value: _ctx.coverFormState.query,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.coverFormState.query) = $event)),
              name: "query",
              placeholder: _ctx.t('Enter your brand name here for a pleasant surprise')
            }, null, 8, ["value", "placeholder"])
          ]),
          _: 1
        }, 8, ["model", "onFinish"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CreatorContentMedia, {
            "query-filter": _ctx.queryState,
            creator: _ctx.coverFormState.creatorQuery,
            "onUpdate:creator": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.coverFormState.creatorQuery) = $event)),
            onOnCreatorSearch: _ctx.submitHandler
          }, null, 8, ["query-filter", "creator", "onOnCreatorSearch"])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.isCartEmpty)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_a_typography_text, { class: "leading-none" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Your cart is empty.")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_typography_text, { class: "leading-none" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Add some content now.")), 1)
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createBlock(_component_a_typography_text, {
                    key: 1,
                    class: "leading-none"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Your cart")), 1)
                    ]),
                    _: 1
                  }))
            ]),
            (_ctx.cartPriceLoading)
              ? (_openBlock(), _createBlock(_component_a_skeleton_button, { key: 0 }))
              : (_openBlock(), _createBlock(_component_a_typography_title, {
                  key: 1,
                  level: 4,
                  class: "creator-content-search__footer__price m-0 leading-none"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formattedCartPrice), 1)
                  ]),
                  _: 1
                }))
          ]),
          (!_ctx.isCartEmpty)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                onClick: _ctx.handleCheckout,
                loading: _ctx.cartPriceLoading,
                disabled: _ctx.cartPriceLoading,
                class: "creator-content-search__checkout-button"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_ShoppingCartOutlined)
                ]),
                default: _withCtx(() => [
                  (!_ctx.isXs)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.t("Proceed to checkout")), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}