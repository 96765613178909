
import CreatorContentCoverForm from "@/shared/components/CreatorContent/CreatorContentCoverForm.vue";
import CreatorContentCoverHeading from "@/shared/components/CreatorContent/CreatorContentCoverHeading.vue";
import CreatorContentLayout from "@/shared/components/CreatorContent/CreatorContentLayout.vue";
import CreatorContentMedia from "@/shared/components/CreatorContent/CreatorContentMedia.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useCreatorContentCart } from "@/shared/composables/useCreatorContentCart";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { useStickyFix } from "@/shared/composables/useStickyFix";
import { scrollToElement } from "@/shared/utils/browser";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import routeNames from "@/web/router/routeNames";
import { SearchOutlined } from "@ant-design/icons-vue";
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ShoppingCartOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    const { t, n } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { isXs } = useWindowSizeChecker();
    const { redirectToCreatorContentSearch } = useRedirectHelper();

    /** ?brand=<brand> */
    const getBrandFromRoute = () => String(route.query.brand ?? "");
    /** ?by=<creator> */
    const getCreatorFromRoute = () => String(route.query.by ?? "");

    /** Internal Form State */
    const coverFormState = reactive({
      query: getBrandFromRoute(),
      creatorQuery: getCreatorFromRoute(),
    });

    /** Reactive Variable used by API */
    const queryState = reactive({
      query: getBrandFromRoute(),
      creatorQuery: getCreatorFromRoute(),
    });

    watch(route, () => {
      queryState.query = getBrandFromRoute();
      queryState.creatorQuery = getCreatorFromRoute();
    });

    const submitHandler = async () => {
      await redirectToCreatorContentSearch({
        query: coverFormState.query,
        creator: coverFormState.creatorQuery,
        // Override default hash of "#app" to disable scroll on route change
        hash: "#",
      });

      setTimeout(() => {
        scrollToElement("content");
      }, 150);
    };

    const { isCartEmpty, formattedCartPrice, cartPriceLoading } =
      useCreatorContentCart({ disableCartListQuery: true });

    const handleCheckout = () => {
      router.push({ name: routeNames.creatorContentCart });
    };

    onMounted(() => {
      if (getBrandFromRoute() || getCreatorFromRoute()) {
        scrollToElement("content");
      }
    });

    // Sticky Fix for Mobile
    useStickyFix("creator-content-search__footer");

    return {
      t,
      n,
      isXs,
      coverFormState,
      queryState,
      submitHandler,
      isCartEmpty,
      formattedCartPrice,
      handleCheckout,
      cartPriceLoading,
    };
  },
  components: {
    CreatorContentLayout,
    CreatorContentCoverHeading,
    CreatorContentCoverForm,
    CreatorContentMedia,
    InputText,
    SearchOutlined,
    ShoppingCartOutlined,
  },
});
