
import CreatorContentMediaItem from "@/shared/components/CreatorContent/CreatorContentMediaItem.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useCreatorContentSubmissions } from "@/shared/composables/useCreatorContent";
import { useCreatorContentCart } from "@/shared/composables/useCreatorContentCart";
import { useExecuteOnScrollToElement } from "@/shared/composables/useScrollFunctions";
import { openInNewTab } from "@/shared/utils/browser";
import { SearchOutlined } from "@ant-design/icons-vue";
import { CreatorContentSubmissionsInput } from "__generated__/globalTypes";
import { isEmpty } from "lodash";
import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  watch,
} from "vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import StackedGrid from "@/shared/components/StackedGrid.vue";

export default defineComponent({
  props: {
    queryFilter: {
      type: Object as PropType<
        Omit<CreatorContentSubmissionsInput, "ids" | "pagination">
      >,
      required: true,
    },
    creator: String,
    showDeleteButton: Boolean,
    hideAddToCart: Boolean,
  },
  emits: ["on-creator-search"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const creatorModel = useVModel(props, "creator");

    /** Used for internal form state. Only update external on submit (form finish) */
    const internalState = reactive({
      creator: creatorModel.value,
    });

    const {
      creatorContentSubmissions,
      creatorContentSubmissionsLoading,
      creatorContentSubmissionsFetchMore,
      creatorContentSubmissionsFetchMoreLoading,
      creatorContentSubmissionsHasNextPage,
      creatorContentSubmissionsFilter,
    } = useCreatorContentSubmissions({
      initialInput: { ...props.queryFilter },
      pageSize: 18,
    });

    /** Update API filters */
    watch(props.queryFilter, (filter) => {
      creatorContentSubmissionsFilter.query = filter.query;
      creatorContentSubmissionsFilter.creatorQuery = filter.creatorQuery;
    });

    const { creatorContentCartStore, addToCart, removeFromCart } =
      useCreatorContentCart({ disableCartListQuery: true });

    const creatorContentSubmissionsEmpty = computed(
      () => creatorContentSubmissions.value?.length === 0
    );

    const resultTitleMessage = (
      contentQuery: string,
      creatorQuery: string,
      haveResults = false
    ) => {
      let startingPhrase = "Sorry, we couldn't find any submitted contents";
      if (haveResults) {
        startingPhrase = "Submitted contents";
      }
      let endingPhrase = "";
      let literalVariables = {};
      if (!isEmpty(contentQuery)) {
        endingPhrase += ' for "{query}"';
        literalVariables["query"] = contentQuery;
      }
      if (!isEmpty(creatorQuery)) {
        endingPhrase += ' from "{creator}"';
        literalVariables["creator"] = creatorQuery;
      }
      return t(startingPhrase + endingPhrase, literalVariables);
    };

    const searchResultTitle = computed(() => {
      const contentQuery = creatorContentSubmissionsFilter.query ?? "";
      const creatorQuery = creatorContentSubmissionsFilter.creatorQuery ?? "";
      return resultTitleMessage(contentQuery, creatorQuery, true);
    });

    const noResultTitle = computed(() => {
      const contentQuery = creatorContentSubmissionsFilter.query ?? "";
      const creatorQuery = creatorContentSubmissionsFilter.creatorQuery ?? "";
      return resultTitleMessage(contentQuery, creatorQuery);
    });

    const scrollElementId = "creator-content-media__list";

    const { initialize: initAutoLoad, destroy: destroyAutoLoad } =
      useExecuteOnScrollToElement(
        scrollElementId,
        () => creatorContentSubmissionsFetchMore(),
        () =>
          creatorContentSubmissionsFetchMoreLoading.value ||
          !creatorContentSubmissionsHasNextPage.value,
        // Trigger early, before reaching the screen
        -(window.innerHeight * 1.5)
      );

    onMounted(() => {
      initAutoLoad();
    });

    onUnmounted(() => {
      destroyAutoLoad();
    });

    const handleContact = () => {
      openInNewTab("https://www.kamilas4am.com/contact-us");
    };

    const handleSubmit = () => {
      creatorModel.value = internalState.creator; // Update external form state
      emit("on-creator-search");
    };

    const contentQueryString = computed(
      () => creatorContentSubmissionsFilter.query
    );

    const creatorQueryString = computed(
      () => creatorContentSubmissionsFilter.creatorQuery
    );

    return {
      t,
      handleSubmit,
      creatorContentSubmissions,
      creatorContentSubmissionsLoading,
      creatorContentSubmissionsFetchMore,
      creatorContentSubmissionsFetchMoreLoading,
      creatorContentSubmissionsHasNextPage,
      creatorContentSubmissionsEmpty,
      searchResultTitle,
      noResultTitle,
      isEmpty,
      handleContact,
      addToCart,
      removeFromCart,
      creatorContentCartStore,
      contentQueryString,
      creatorQueryString,
      creatorModel,
      internalState,
    };
  },
  components: {
    CreatorContentMediaItem,
    FormGroup,
    InputText,
    SearchOutlined,
    StackedGrid,
  },
});
