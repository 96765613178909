import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "stacked-grid"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_ctx.loading || _ctx.loadMoreLoading || _ctx.items)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loading && !_ctx.loadMoreLoading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_a_row, {
                gutter: [_ctx.colGutter ?? 0],
                wrap: false,
                class: "stacked-grid__a-row"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "columns", _normalizeProps(_guardReactiveProps({ columns: _ctx.columns })))
                ]),
                _: 3
              }, 8, ["gutter"]),
              (_ctx.hasNextPage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_a_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
                      loading: _ctx.loadMoreLoading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ]))
    : _renderSlot(_ctx.$slots, "empty", { key: 1 })
}