import { onMounted, onUnmounted, ref } from "vue";
import debounce from "lodash/debounce";

/**
 * Injects an "empty" transparent <div> that will trigger a re-render on scroll.
 * Used to fix issues with `position: sticky` on mobile browsers with "header" URL bar that hides on scroll.
 * See: #865d6mhwj
 */
export const useStickyFix = (elementId: string) => {
  const renderElement = ref<HTMLElement | null | undefined>();

  const listener = debounce(() => {
    if (renderElement.value) {
      renderElement.value.innerHTML = renderElement.value.innerHTML ? "" : "0";
    }
  }, 250);

  /** Inject empty div on mount */
  onMounted(() => {
    const elementById = document.getElementById(elementId);

    if (elementById) {
      const emptyDiv = document.createElement("div");
      emptyDiv.style.cssText =
        "position: fixed; bottom: 0; pointerEvents: none; color: transparent;";

      renderElement.value = emptyDiv;

      elementById.appendChild(emptyDiv);
    }

    window.addEventListener("scroll", listener);
  });

  /** Remove event listeners */
  onUnmounted(() => {
    window.removeEventListener("scroll", listener);
  });
};
